<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-card class="elevation-12" outlined>
          <v-card-title class="text-center">
            <v-img
              src="../../assets/images/ic-logo.png"
              max-width="100"
              contain
              class="mb-2 mr-5"
            />
            <h2>File Upload</h2>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-file-input
              v-model="fileInput"
              label="Drag and drop a file here or click to select"
              prepend-icon="mdi-paperclip"
              accept=".jpg, .jpeg, .png, .pdf, .doc, .docx"
              :rules="[rules.required]"
              class="my-4"
              outlined
              clearable
            >
              <template v-slot:label>
                <span class="text-body-1">Choose a file</span>
              </template>
            </v-file-input>

            <v-list v-if="fileInput">
              <v-list-item class="my-1">
                <v-list-item-icon>
                  <v-icon>{{ getFileIcon(fileInput.name) }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ fileInput.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    formatFileSize(fileInput.size)
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-select
              :items="users"
              label="Select User"
              v-model="userId"
              item-text="email"
              item-value="_id"
            ></v-select>

            <!-- Date Picker for File Date -->
            <v-menu
              v-model="fileDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fileDate"
                  label="Select File Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[rules.required]"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fileDate"
                @input="fileDatePicker = false"
              ></v-date-picker>
            </v-menu>

            <!-- Date Picker for Expiry Date -->
            <v-menu
              v-model="expiryDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="expiryDate"
                  label="Select Expiry Date (Optional)"
                  prepend-icon="mdi-calendar-remove"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="expiryDate"
                @input="expiryDatePicker = false"
              ></v-date-picker>
            </v-menu>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              color="primary"
              @click="uploadFiles"
              class="ma-4"
              elevation="2"
              :disabled="!fileInput || !fileDate"
            >
              Upload
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RestResource from "../../services/dataServiceAdmin";
const service = new RestResource();

export default {
  data() {
    return {
      fileInput: null,
      fileDate: null,
      expiryDate: null,
      fileDatePicker: false,
      expiryDatePicker: false,
      userId: null,
      rules: {
        required: (value) => !!value || "This field is required.",
      },
      users: []
    };
  },

  mounted() {
    this.getUsers();
  },
  methods: {
    async uploadFiles() {
      const formy = new FormData();

      if (this.fileInput) {
        formy.append("file", this.fileInput);
      }
      if (this.fileDate) {
        formy.append("fileDate", this.fileDate);
      }
      if (this.expiryDate) {
        formy.append("expiryDate", this.expiryDate);
      }

      if (this.userId) {
        formy.append("userId", this.userId);
      }

      this.$setLoader();
      try {
        const response = await service.uploadFile(formy);
        console.log(response.data);
        this.$emit("success", response.data);
        this.fileInput = null;
        this.fileDate = null;
        this.expiryDate = null;
      } catch (error) {
        console.error(error);
        this.$emit("error", error);
      } finally {
        this.$disableLoader();
      }
    },

    async getUsers() {
      this.$setLoader();
      try {
        const response = await service.getUsers();
        this.users = response.data;
      } catch (error) {
        alert(error)
      } finally {
        this.$disableLoader();
      }
    },

    getFileIcon(fileName) {
      const extension = fileName.split(".").pop().toLowerCase();
      switch (extension) {
        case "jpg":
        case "jpeg":
        case "png":
          return "mdi-image";
        case "pdf":
          return "mdi-file-pdf";
        case "doc":
        case "docx":
          return "mdi-file-document";
        default:
          return "mdi-file";
      }
    },
    formatFileSize(size) {
      const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
      const sizes = ["Bytes", "KB", "MB", "GB"];
      return `${Math.round(size / Math.pow(1024, i), 2)} ${sizes[i]}`;
    },
  },
};
</script>

<style scoped>
.v-card {
  border-radius: 12px;
  padding: 20px;
}

.v-card-title h2 {
  font-size: 24px;
  color: #1976d2;
}

.v-text-field {
  margin-bottom: 16px;
}
</style>
